import * as React from 'react';

import * as ToastPrimitive from '@radix-ui/react-toast';

import { toast, toastViewport } from './Toast.css';
import { NotificationBox , NotificationProps } from '../Notification/NotificationBox';

const DEFAULT_DURATION = 3000;

type ToastPosition = 'top' | 'bottom' | 'left' | 'right';

export type ToastConfig = {
  open: boolean;
  position: ToastPosition;
  duration: number;
  notification: NotificationProps;
};

type ToastContextType = {
  config: ToastConfig;
  showToast: (
    props: NotificationProps & {
      position?: ToastConfig['position'];
      duration?: ToastConfig['duration'];
    },
  ) => void;
  hideToast: () => void;
};

const initialConfig: ToastConfig = {
  open: false,
  position: 'bottom',
  duration: DEFAULT_DURATION,
  notification: {} as NotificationProps,
};

export const ToastContext = React.createContext<ToastContextType | null>(null);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = React.useState<ToastConfig>(initialConfig);

  const showToast = (
    props: NotificationProps & {
      position?: 'top' | 'bottom' | 'left' | 'right';
      duration?: number;
    },
  ) => {
    const { position = initialConfig.position, duration = initialConfig.duration, ...notification } = props;
    setConfig({
      open: true,
      position,
      duration,
      notification,
    });
  };

  const hideToast = () => setConfig(prev => ({ ...prev, open: false }));

  return (
    <ToastContext.Provider value={{ config, showToast, hideToast }}>
      <Toast />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = (defaults?: { position?: 'top' | 'bottom' | 'left' | 'right'; duration?: number }) => {
  const context = React.useContext(ToastContext);
  if (!context) throw new Error('useToast must be used within SparkyProvider');

  return {
    showToast: (
      props: NotificationProps & {
        position?: ToastConfig['position'];
        duration?: ToastConfig['duration'];
      },
    ) => {
      context.showToast({
        position: defaults?.position ?? 'bottom',
        duration: defaults?.duration ?? DEFAULT_DURATION,
        ...props,
      });
    },
    hideToast: context.hideToast,
  };
};

export const Toast = () => {
  const context = React.useContext(ToastContext);
  if (!context) return null;

  const { config, hideToast } = context;

  if (!config.notification?.text || !config.notification?.title) {
    return null;
  }

  return (
    <ToastPrimitive.Provider swipeDirection={getSwipeDirection(config.position)}>
      <ToastPrimitive.Root
        duration={config.duration}
        open={config.open}
        onOpenChange={hideToast}
        className={toast({ position: config.position })}>
        <NotificationBox {...config.notification} />
      </ToastPrimitive.Root>
      <ToastPrimitive.Viewport className={toastViewport({ position: config.position })} />
    </ToastPrimitive.Provider>
  );
};

const getSwipeDirection = (position: ToastConfig['position']) => {
  switch (position) {
    case 'bottom':
    case 'top':
      return 'down';
    default:
      return position;
  }
};
