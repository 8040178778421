import React, { ComponentType } from 'react';

import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';

const StackItemStyled = styled('div', {
  flexGrow: 0,
  variants: {
    grow: {
      true: {
        flexGrow: 1,
        flexBasis: 0,
      },
      false: {
        flexGrow: 0,
      },
    },
    shrink: {
      true: {
        flexShrink: 1,
      },
      false: {
        flexShrink: 0,
      },
    },
  },
  defaultVariants: {
    shrink: true,
  },
});

const stitchesClassName = 'sparky-stack-item';

export const StackItem = React.forwardRef<HTMLDivElement, React.PropsWithChildren<StackItemProps>>(
  ({ children, as = 'div', className = '', ...props }, ref) => {
    const variantProps = extractVariantProps(props);

    return (
      <StackItemStyled ref={ref} as={as} {...variantProps} className={`${stitchesClassName} ${className}`}>
        {children}
      </StackItemStyled>
    );
  },
);

StackItemStyled.displayName = 'styled(Stack.Item)';

type StackItemVariants = TransformStitchesToSparky<typeof StackItemStyled>;

type StackItemProps = StackItemVariants & {
  as?: keyof JSX.IntrinsicElements | ComponentType<Record<string, unknown>>;
  className?: never;
};
