import * as animation from './animation';
import * as border from './border';
import * as color from './color';
import * as opacity from './opacity';
import * as shadow from './shadow';
import * as size from './size';
import * as space from './space';
import * as typography from './typography';
import { resolveTokensReferences } from '../../../../util/css';
import * as components from '../components';

export default resolveTokensReferences({
  ...animation,
  ...border,
  ...color,
  ...opacity,
  ...shadow,
  ...size,
  ...space,
  ...typography,
  ...components,
});
