import { FC, useEffect } from 'react';

import { I18nTranslationProvider } from './i18n';
import { Locale, LocaleProvider } from './locale';
import { MediaQueryProvider } from './mediaQuery';
import { ToastProvider } from '../components/Toast/Toast';
import { themeClassNames as stitchesThemeClassNames } from '../stitches.config';
import { themeClassNames as vanillaExtractThemeClassNames } from '../theme.css';
import { getClassNames } from '../util/css';
import getI18nTranslations from '../util/i18nTranslations';

export const THEME_DATA_IDENTIFIER = 'themeclasses';

interface Props {
  locale?: Locale;
  platformHint?: 'desktop' | 'mobile';
  theme?: string;
}

export const SparkyProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  locale = 'nl-NL',
  platformHint,
  theme = 'default',
}) => {
  const themeClasses = getClassNames(
    stitchesThemeClassNames[theme] ?? stitchesThemeClassNames['default'],
    vanillaExtractThemeClassNames[theme] ?? vanillaExtractThemeClassNames['default'],
  );

  // While the theme class is added by the SparkyProvider, theming isn't applied to elements (that are added) outside
  //  of the Provider (for isntance dialogs, that are appended as immediate children of the body). For these cases, the
  //  SparkyProvider also adds the theme classes to the body.
  useEffect(() => {
    // Check whether theme classes were already added to the body, so we can remove them before adding the new ones
    const existingThemeClasses = document.body.dataset[THEME_DATA_IDENTIFIER]?.split(' ') ?? [];

    document.body.classList.remove(...existingThemeClasses);
    document.body.classList.add(...themeClasses.split(' '));

    document.body.dataset[THEME_DATA_IDENTIFIER] = themeClasses;
  }, [theme, themeClasses]);

  return (
    <div className={themeClasses}>
      <ToastProvider>
        <MediaQueryProvider platformHint={platformHint}>
          <LocaleProvider locale={locale}>
            <I18nTranslationProvider labels={getI18nTranslations(locale)}>{children}</I18nTranslationProvider>
          </LocaleProvider>
        </MediaQueryProvider>
      </ToastProvider>
    </div>
  );
};
