import { theme } from './default/theme';
import tokens from './default/tokens';
import { theme as rebrandingTheme } from './rebranding/theme';
import { getFontFaces } from '../../util';

const globalStyles = {
  '@font-face': getFontFaces(tokens.fonts),
  '@media (prefers-reduced-motion: no-preference)': {
    ':root': {
      'scroll-behavior': 'smooth',
    },
  },
  ':root': {
    fontSize: tokens.BASE_FONT_RELATIVE,
  },
  body: {
    color: theme.colors.textPrimary,
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes.BodyM,
    fontWeight: theme.fontWeights.bodyRegular,
    lineHeight: theme.lineHeights['6'],
    margin: 0,

    '&#nl-eneco-selfservice': {
      display: 'block',
      backgroundColor: theme.colors.backgroundSecondary,
    },
  },

  'a, button': {
    '&:focus-visible': {
      outline: theme.outlines.outlineFocus,
    },
    '@supports not selector(:focus-visible)': {
      '&:focus': {
        outline: theme.outlines.outlineFocus,
      },
    },
  },

  'button, input, select, textarea': {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.bodyRegular,
  },

  '.seamly-placeholder': {
    zIndex: 2,
    position: 'relative',
    pointerEvents: 'all',
  },
};

const themes = {
  default: theme,
  rebranding: rebrandingTheme,
};

export const BASE_FONT_RELATIVE = tokens.BASE_FONT_RELATIVE;
export const iconSizes = tokens.iconSizes;
export { globalStyles, theme, themes };
