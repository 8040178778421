export { createVariants } from './vanilla-extract';

type classObject = { [key: string]: boolean };

/**
 * This utility will accept strings as arguments and returns a string with all the arguments concatenated and separated by a
 * space. Useful for generating a list of conditional classNames.
 *
 * @param args A list of strings
 * @returns A string with all the arguments concatenated and separated by a space
 *
 * @example
 * const isVisible = true;
 * const isAlert = false;
 * const isDisabled = { isDisabled: true, isHidden: false, isNotification: true }
 *
 * const classNames = getClassNames('notification', isVisible && 'visible', isAlert && 'alert', { isDisabled: true });
 *
 * // classNames = 'notification visible isDisabled isNotification'
 */
export function getClassNames(...args: Array<boolean | string | classObject | undefined>): string {
  let classNames = '';

  for (const arg of args) {
    if (typeof arg === 'string') {
      classNames += ` ${arg}`;
    } else if (typeof arg === 'object' && arg !== null) {
      classNames += Object.keys(arg).reduce((acc, key) => `${acc}${arg[key] ? ` ${key}` : ''}`, '');
    }
  }

  return classNames.trim();
}

/**
 * Type representing a value in a token system.
 * Values can be strings, numbers, or arrays of TokenValues.
 */
type TokenValue = string | number | TokenValue[];

/**
 * Type representing a generic object containing tokens.
 * The values can be either a primitive TokenValue or nested TokenObject or TokenObject arrays.
 */
type TokenObject = {
  [key: string]: TokenValue | TokenObject | TokenObject[];
};

/**
 * Resolves token references in an object, supports mathematical operations,
 * array references, and deep nesting.
 * It replaces `{{ token }}` or `{{ token * 2 }}` in the target object with the corresponding value from the source.
 * Supports deep nesting and basic mathematical operations (e.g., {{ GRID * 2 }}).
 *
 * The function ensures that the returned object maintains the same structure as the original, while resolving
 * token references and calculations.
 *
 * @param tokens - The object containing tokenized values that need to be resolved.
 * @returns A new object with all token references replaced with actual values, maintaining the original structure.
 *
 * @example
 * const tokens = resolveTokensReferences(tokens);
 * // tokens = { color: { primary: '{{ primaryRed }}' } }
 * // tokens = { color: { primary: '#F00' } }
 */
export function resolveTokensReferences<T extends TokenObject>(tokens: T): T {
  /**
   * Helper function to recursively resolve token placeholders and calculations.
   * This function is responsible for processing a single token value, which can be a string, number,
   * array, or object.
   *
   * @param value - The value to resolve, which could be a string, number, object, or array.
   * @returns The resolved value with all tokens replaced.
   */
  function resolve(value: TokenValue | TokenObject | TokenObject[]): TokenValue | TokenObject | TokenObject[] {
    // If the value is a string, attempt to resolve any token references
    if (typeof value === 'string') {
      // Resolve token placeholders (e.g., {{token}} or calculations like {{GRID * 2}})
      const resolvedValue = value.replace(/\{\{\s*(.*?)\s*\}\}/g, (_, expression: string) => {
        try {
          // Handle array-like syntax in tokens (e.g., typography.fontSizes[3XL])
          const resolvedExpression = expression.replace(/\[([^\]]+)\]/g, (_, inner: string) => {
            // Add quotes around keys that are identifiers but not valid variable names
            if (/^\d/.test(inner)) {
              return `['${inner}']`;
            }
            return `.${inner}`;
          });

          // Create a function to evaluate the expression using source values
          // eslint-disable-next-line no-new-func
          const fn = new Function(...Object.keys(tokens), `return ${resolvedExpression.trim()};`);
          const evaluated = fn(...Object.values(tokens));

          // Ensure that the result is of the expected TokenValue type (string or number)
          return typeof evaluated === 'number' || typeof evaluated === 'string' ? String(evaluated) : expression; // Fallback to the original expression if not resolvable
        } catch {
          // console.error('Error resolving token: ', expression);
          return expression;
        }
      });

      // If resolvedValue has changed, check for further unresolved tokens and resolve again
      if (resolvedValue.includes('{{') && resolvedValue.includes('}}')) {
        return resolve(resolvedValue); // Re-run resolve if there are still tokens to resolve
      }

      return resolvedValue;
    } else if (Array.isArray(value)) {
      // Recursively resolve all elements in an array
      return value.map(resolve) as TokenObject[];
    } else if (typeof value === 'object' && value !== null) {
      // Recursively resolve all keys in an object
      const resolvedObject: TokenObject = {};
      for (const [key, val] of Object.entries(value)) {
        resolvedObject[key] = resolve(val);
      }
      return resolvedObject;
    }

    // Return primitive values (numbers, strings, etc.) unchanged
    return value;
  }

  return resolve(tokens) as T;
}
