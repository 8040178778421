import * as rebrandedBorder from './border';
import * as rebrandedColor from './color';
import { resolveTokensReferences } from '../../../../util/css';
import _defaultTokens from '../../default/tokens';
import * as components from '../components';

export default resolveTokensReferences({
  ..._defaultTokens,
  ...rebrandedBorder,
  ...rebrandedColor,
  ...components,
});
