export const borderWidths = {
  none: '0',
  s: '1px',
  m: '2px',
  l: '3px',
};

export const outlines = {
  outlineFocus: '{{ borderWidths.m }} solid {{ borderColors.borderFocus }}',
  outlineInputFocus: '{{ borderWidths.s }} solid {{ borderColors.borderFocus }}',
};

export const radii = {
  none: '0',
  xs: '{{ GRID }}px',
  s: '{{ GRID }}px',
  m: '{{ GRID * 2 }}px',
  l: '{{ GRID * 3 }}px',
  xl: '{{ GRID * 3 }}px',
  round: '9999px',
};
