export const enecoHeaderColors = {
  navBackgroundPrimary: '{{ neutralColors.neutral100 }}',
  navBackgroundSecondary: '{{ neutralColors.neutral200 }}',
  navBackgroundTertiary: '{{ neutralColors.neutralWhite }}',
  navTextLabel: '{{ secondaryColors.accentGreen700 }}',
};

export const enecoHeaderDividerHeight = {
  navDividerHeight: '{{ borderWidths.none }}',
};
