export const space = {
  0: '0px',
  1: '{{ GRID * 1 }}px',
  2: '{{ GRID * 2 }}px',
  3: '{{ GRID * 3 }}px',
  4: '{{ GRID * 4 }}px',
  5: '{{ GRID * 5 }}px',
  6: '{{ GRID * 6 }}px',
  7: '{{ GRID * 7 }}px',
  8: '{{ GRID * 8 }}px',
  10: '{{ GRID * 10 }}px',
  12: '{{ GRID * 12 }}px',
  16: '{{ GRID * 16 }}px',
  24: '{{ GRID * 24 }}px',
  32: '{{ GRID * 32 }}px',
};
