import tokens from './tokens';

const theme = {
  colors: {
    ...tokens.globalThemeColors,
    ...tokens.button.enecoButtonColors,
    ...tokens.expandable.enecoExpandableTokens,
    ...tokens.desktopHeader.enecoHeaderColors,
    ...tokens.messageBar.enecoMessagebarColors,
    ...tokens.navLink.enecoNavlinkOncolorColors,
    ...tokens.progressIndicator.enecoProgressIndicatorTokensColors,
    ...tokens.ribbon.enecoRibbonColors,
    ...tokens.badge.enecoBadgeColors,
  },
  shadows: {
    ...tokens.boxShadows,
    ...tokens.borderShadows,
  },
  outlines: tokens.outlines,
  fonts: {
    body: 'Etelka, sans-serif',
    heading: 'Etelka, sans-serif',
  },
  fontSizes: tokens.fontSizes,
  fontWeights: tokens.fontWeights,
  letterSpacings: tokens.letterSpacings,
  lineHeights: tokens.lineHeights,
  space: tokens.space,
  sizes: tokens.sizes,
  borderWidths: {
    ...tokens.borderWidths,
    ...tokens.button.enecoButtonBorderWidths,
    ...tokens.desktopHeader.enecoHeaderDividerHeight,
  },
  radii: {
    ...tokens.radii,
    ...tokens.button.enecoButtonBorderRadii,
  },
  transitions: tokens.transitions,
  typography: {
    ...tokens.bodyTypography,
    ...tokens.headingTypography,
  },
  opacities: tokens.opacities,
  display: {
    ...tokens.button.enecoButtonChevronDisplay,
  },
  components: {
    // @todo all component tokens should be collected here eventually..
    image: tokens.image.enecoImageTokens,
  },
};

export { theme };
