// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgStarStrokeIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fill="none"
      stroke="inherit"
      strokeWidth={2}
      d="M20.921 9.731c.078 0 .148.034.195.086l.036.105a.253.253 0 0 1-.09.277l-.007.004-4.893 3.566-.458.334.175.54 1.875 5.766.002.005.001.004a.251.251 0 0 1-.091.28l-.003.002a.245.245 0 0 1-.157.049.243.243 0 0 1-.155-.049l-4.893-3.565-.46-.333-.458.333-4.895 3.566-.007.005a.248.248 0 0 1-.297 0 .274.274 0 0 1-.097-.302l.001-.001 1.872-5.76.176-.54-.459-.334-4.89-3.563a.272.272 0 0 1-.094-.301.258.258 0 0 1 .245-.174h6.617l.176-.54 1.874-5.766.001-.005a.263.263 0 0 1 .246-.175c.114 0 .214.078.245.175l.002.005 1.873 5.766.177.54h6.615Z"
    />
  </Icon>
);
export default SvgStarStrokeIcon;
SvgStarStrokeIcon.displayName = 'SvgStarStrokeIcon';
