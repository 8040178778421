// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgDeleteIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M19.692 2.756h-3.624C16.034 1.502 15.02.5 13.778.5h-3.561c-1.242 0-2.25 1.002-2.29 2.256h-3.62c-.993 0-1.807.852-1.807 1.874v2.707c0 1.002.775 1.82 1.74 1.854l.024 11.462C4.268 22.227 5.516 23.5 7.04 23.5h9.915c1.53 0 2.772-1.273 2.777-2.847l.029-11.467c.964-.035 1.739-.852 1.739-1.85V4.63c0-1.022-.814-1.874-1.808-1.874m-9.47-.552h3.561c.327 0 .6.25.629.552H9.589a.636.636 0 0 1 .633-.552m6.733 19.592h-9.91c-.618 0-1.12-.521-1.12-1.158L5.901 9.171h12.203l-.024 11.467c-.005.637-.506 1.158-1.125 1.158m2.889-14.48c0 .086-.064.15-.146.15H4.303a.146.146 0 0 1-.146-.15V4.61c0-.085.063-.15.146-.15h15.395c.082 0 .146.065.146.15z" />
  </Icon>
);
export default SvgDeleteIcon;
SvgDeleteIcon.displayName = 'SvgDeleteIcon';
