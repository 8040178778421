import { Fonts } from '../../../../types';

export const BASE_FONT_SIZE = 10;
export const BASE_FONT_RELATIVE = '{{ (BASE_FONT_SIZE / 16) * 100 }}%';

/**
 * Primary tokens
 */
export const fonts: Fonts = [
  ['Etelka', 300, 'etelkaLight.woff2', 'woff2'],
  ['Etelka', 500, 'etelkaMedium.woff2', 'woff2'],
  ['Etelka', 700, 'etelkaBold.woff2', 'woff2'],
  ['Etelka', 900, 'etelkaBlack.woff2', 'woff2'],
];

export const fontSizes = {
  12: '1.2rem',
  14: '1.4rem',
  16: '1.6rem',
  18: '1.8rem',
  20: '2rem',
  24: '2.4rem',
  32: '3.2rem',
  40: '4rem',
  48: '4.8rem',
  64: '6.4rem',
  80: '8rem',

  '3XS': '1.6rem',
  '2XS': '1.8rem',
  XS: '2rem',
  S: '2.4rem',
  M: '3.2rem',
  L: '4rem',
  XL: '4.8rem',
  '2XL': '6.4rem',
  '3XL': '8rem',

  BodyXS: '1.2rem',
  BodyS: '1.4rem',
  BodyM: '1.6rem',
  BodyL: '1.8rem',
  BodyXL: '2.0rem',
};

export const fontWeights = {
  bodyRegular: '300',
  bodyBold: '500',
  headingLight: '500',
  headingRegular: '700',
};

export const letterSpacings = {
  narrowM: '-2px',
  narrowS: '-1px',
  normal: '0',
  wide: '0.3px',
};

export const lineHeights = {
  4: '1.6rem',
  5: '2rem',
  6: '2.4rem',
  7: '2.8rem',
  9: '3.6rem',
  10: '4rem',
  12: '4.8rem',
  16: '6.4rem',
  20: '8rem',
};

/**
 * Semantic tokens
 */
export const bodyTypography = {
  bodyXL: {
    fontSize: '{{ fontSizes.BodyXL }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[7] }}',
  },
  bodyL: {
    fontSize: '{{ fontSizes.BodyL }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[7] }}',
  },
  bodyM: {
    fontSize: '{{ fontSizes.BodyM }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[6] }}',
  },
  bodyS: {
    fontSize: '{{ fontSizes.BodyS }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[5] }}',
  },
  bodyXS: {
    fontSize: '{{ fontSizes.BodyXS }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[4] }}',
  },
  quoteM: {
    fontSize: '{{ fontSizes[32] }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[9] }}',
  },
  quoteS: {
    fontSize: '{{ fontSizes[24] }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[7] }}',
  },
};

export const headingTypography = {
  heading3xl: {
    fontSize: '{{ fontSizes[3XL] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.narrowM }}',
    lineHeight: '{{ lineHeights[20] }}',
  },
  heading2xl: {
    fontSize: '{{ fontSizes[2XL] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.narrowM }}',
    lineHeight: '{{ lineHeights[16] }}',
  },
  headingXl: {
    fontSize: '{{ fontSizes.XL }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.narrowM }}',
    lineHeight: '{{ lineHeights[12] }}',
  },
  headingL: {
    fontSize: '{{ fontSizes.L }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.narrowS }}',
    lineHeight: '{{ lineHeights[10] }}',
  },
  headingM: {
    fontSize: '{{ fontSizes.M }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[9] }}',
  },
  headingS: {
    fontSize: '{{ fontSizes.S }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[7] }}',
  },
  headingXs: {
    fontSize: '{{ fontSizes.XS }}',
    fontWeight: '{{ fontWeights.headingLight }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[6] }}',
  },
  heading2xs: {
    fontSize: '{{ fontSizes[2XS] }}',
    fontWeight: '{{ fontWeights.headingLight }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[6] }}',
  },
  heading3xs: {
    fontSize: '{{ fontSizes[3XS] }}',
    fontWeight: '{{ fontWeights.headingLight }}',
    letterSpacing: '{{ letterSpacings.wide }}',
    lineHeight: '{{ lineHeights[5] }}',
  },
};
