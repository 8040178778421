import { BreakPointTypes, MediaQueryKeys } from './types';
import type { VariantConditions } from './util/css/vanilla-extract/conditionalRecipe';

export const mediaQueryKeys: MediaQueryKeys[] = ['initial', 'sm', 'md', 'lg', 'xl'];

export const mediaQueries: { [key in BreakPointTypes]: string } = {
  sm: '(min-width: 375px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1440px)',
};

export const variantConditions: VariantConditions = {
  sm: {
    type: '@media',
    query: `screen and (min-width: 375px)`,
  },
  md: {
    type: '@media',
    query: `screen and (min-width: 768px)`,
  },
  lg: {
    type: '@media',
    query: `screen and (min-width: 1024px)`,
  },
  xl: {
    type: '@media',
    query: `screen and (min-width: 1440px)`,
  },
};
