// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgCombineIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M10 6c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2ZM5 16c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm14 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm-3.04-.87L13 13.42V10c0-.55-.45-1-1-1s-1 .45-1 1v3.42l-2.96 1.71c-.48.28-.64.89-.37 1.37.19.32.52.5.87.5.17 0 .34-.04.5-.13L12 15.16l2.96 1.71c.16.09.33.13.5.13a1.002 1.002 0 0 0 .5-1.87Z" />
  </Icon>
);
export default SvgCombineIcon;
SvgCombineIcon.displayName = 'SvgCombineIcon';
