import env from '@common/env';
import { Namespace, LayoutMode, Visibility, ChatArgs } from '@components/chat/useChat';

import { SeamlyCallbackTypeFor, SeamlyInstruction } from './types';

/*
 * Seamly docs:
 * https://peek:%40b00@master-eneco-client.peek.conversationalsdevelopment.nl/
 * https://developers.seamly.ai/clients/web-ui/docs/window-api.html
 */

if (typeof window !== 'undefined') {
  window.seamly = window.seamly ?? [];
}

const instructionQueue: SeamlyInstruction[] = [];
let isInitialized: boolean;

const send = (instruction: SeamlyInstruction) => {
  if (!isInitialized && instruction.action === 'setVisibility') {
    window.seamly.push(instruction);
    return;
  }

  if (!isInitialized && instruction.action !== 'init') {
    instructionQueue.push(instruction);
    return;
  }

  window.seamly.push(instruction);

  if (!isInitialized && instruction.action === 'init') {
    instructionQueue.forEach(instr => {
      window.seamly.push(instr);
    });
    isInitialized = true;
  }
};

export const src = env('CONVERSATIONALS_CHAT_SCRIPT_URL');

export const srcId = 'conversationalUi';

export const init = (
  args: Pick<ChatArgs, 'namespace' | 'parentElement' | 'layoutMode' | 'hideOnNoUserResponse' | 'context' | 'api'>,
): void => {
  send({ action: 'init', args });
};

export const setVisibility = (args: Pick<ChatArgs, 'namespace' | 'visibility'>): void => {
  const { namespace } = args;
  send({
    action: 'setVisibility',
    args: args.visibility,
    instance: namespace,
  });
};

export const askText = (args: Pick<ChatArgs, 'namespace' | 'askText'>): void => {
  const { namespace } = args;
  send({
    action: 'askText',
    args: args.askText,
    instance: namespace,
  });
};

export const on = <Event extends string>(
  event: Event,
  namespace: Namespace,
  callback: SeamlyCallbackTypeFor<Event>,
): void => {
  send({
    action: 'on',
    args: [event, callback],
    instance: namespace,
  });
};

export const off = (event: string, namespace: Namespace): void => {
  send({
    action: 'off',
    args: event,
    instance: namespace,
  });
};

export const setVariables = ({
  variables,
  namespace,
}: {
  variables: ChatArgs['variables'];
  namespace: Namespace;
}): void => {
  on('ui.beforeStart', namespace, () => {
    send({
      action: 'setVariables',
      args: variables,
      instance: namespace,
    });
  });
};

export const topic = ({
  topic,
  fallbackMessage = 'Oeps! Er is iets misgegaan. Probeer het later opnieuw.',
  namespace,
  askText,
}: Pick<ChatArgs, 'topic' | 'fallbackMessage' | 'namespace' | 'askText'>): void => {
  const call: SeamlyInstruction = {
    action: 'setTopic',
    args: {
      name: topic,
      fallbackMessage,
      userTriggered: true,
      ...(askText && { message: askText }),
    },
    instance: namespace,
  };
  send(call);
};

export const destroy = (namespace: Namespace): void => {
  send({
    action: 'destroy',
    instance: namespace,
  });
};

export const getVisibility = (namespace: Namespace): { layoutMode: LayoutMode; visibility: Visibility } => {
  const defaultValue = { layoutMode: null, visibility: null };
  if (typeof window === 'undefined') return defaultValue;

  const key = `cvco.${namespace}.connection.nl-informal`;
  const options = window.sessionStorage.getItem(key) || '{}';

  try {
    const { visibility: v } = JSON.parse(options);
    const layoutMode = 'inline' in v ? 'inline' : 'window' in v ? 'window' : null;
    const visibility = layoutMode ? v[layoutMode] : null;
    return { layoutMode, visibility };
  } catch {
    // Ignore (web storage set by 3rd party; might contain invalid JSON string)
  }
  return defaultValue;
};
