// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgStarIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M22.12 9.621a1.062 1.062 0 0 0-1.009-.733h-6.18l-1.913-5.88a1.064 1.064 0 0 0-1.01-.734c-.457 0-.872.299-1.01.734l-1.913 5.88H2.907c-.457 0-.871.297-1.01.733-.138.436.011.915.382 1.191l4.998 3.636-1.914 5.88c-.137.436.011.914.383 1.191.372.276.883.276 1.254 0l4.997-3.636 4.998 3.636c.192.138.404.202.628.202.223 0 .436-.064.627-.202.372-.266.532-.755.382-1.191l-1.913-5.88 4.997-3.636c.372-.265.531-.755.383-1.191h.021Z" />
  </Icon>
);
export default SvgStarIcon;
SvgStarIcon.displayName = 'SvgStarIcon';
