// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgWifiIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M14.9 15.23c-.26 0-.52-.1-.72-.31-1.2-1.25-3.26-1.25-4.46 0a.996.996 0 1 1-1.44-1.38 5.05 5.05 0 0 1 7.34 0c.38.4.37 1.03-.03 1.41-.19.19-.44.28-.69.28m6.25-5.88c.4-.38.41-1.02.03-1.41-2.46-2.56-5.74-3.97-9.22-3.97S5.2 5.38 2.74 7.94a.996.996 0 1 0 1.44 1.38c2.08-2.17 4.85-3.36 7.78-3.36s5.7 1.19 7.78 3.36c.2.2.46.31.72.31s.5-.09.69-.28m-9.21 7.24a1.71 1.71 0 1 0-.001 3.418 1.71 1.71 0 0 0 .001-3.418m6.24-4.51c.4-.38.41-1.02.03-1.41-1.67-1.74-3.89-2.69-6.26-2.69s-4.59.96-6.26 2.69a.996.996 0 1 0 1.44 1.38c1.29-1.34 3-2.08 4.81-2.08s3.53.74 4.81 2.08c.2.2.46.31.72.31s.5-.09.69-.28z" />
  </Icon>
);
export default SvgWifiIcon;
SvgWifiIcon.displayName = 'SvgWifiIcon';
