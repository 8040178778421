// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgDuplicateIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M17 21c0 .55-.45 1-1 1H6c-1.1 0-2-.9-2-2V7c0-.55.45-1 1-1s1 .45 1 1v13h10c.55 0 1 .45 1 1Zm1-19h-8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Z" />
  </Icon>
);
export default SvgDuplicateIcon;
SvgDuplicateIcon.displayName = 'SvgDuplicateIcon';
