export const GRID = 4;

export const iconSizes = {
  small: '1.6rem',
  medium: '2.4rem',
  large: '3.2rem',
  extraLarge: '6.4rem',
  illustrationSmall: '4rem',
  illustrationMedium: '6rem',
  illustrationLarge: '8rem',
};

export const sizes = {
  1: '768px',
  2: '1024px',
  3: '1220px',
  4: '1432px',
  5: '1680px',
  buttonMinWidth: '80px',
  targetMinWidth: '44px',
  targetMinHeight: '44px',
  inputMinHeight: '48px',
  ...iconSizes,
};
