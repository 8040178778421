import React, { useState, SyntheticEvent, useCallback } from 'react';

import { InfoIcon, SuccessIcon, WarningIcon, ChatIcon, ErrorIcon , Icon, CloseIcon } from '../../icons';
import { useI18nTranslation } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';
import { Box } from '../Box/Box';
import { Heading } from '../Heading/Heading';
import { IconButton } from '../IconButton/IconButton';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';

const LeftIconWrapper = styled(Stack.Item, {
  [`& ${Icon}`]: { fill: '$$iconColor' },
});

export const NotificationBoxStyled = styled('div', {
  position: 'relative',
  borderStyle: 'solid',
  borderWidth: '$s',
  borderRadius: '$m',
  width: '100%',

  [`&, & ${Heading}`]: { color: '$textPrimary' },

  variants: {
    isElevated: {
      true: {
        boxShadow: '$s',
      },
      false: {
        boxShadow: 'none',
      },
    },

    emphasis: {
      high: {
        padding: '$6',
        [`& ${LeftIconWrapper} ${Icon}`]: { iconography: '$large' },
        [`& ${Heading}`]: { typography: '$headingXs' },
        [`& ${Text}`]: { typography: '$bodyM' },
      },
      low: {
        padding: '$4',
        [`& ${LeftIconWrapper} ${Icon}`]: { iconography: '$medium' },
        [`& ${Heading}`]: { typography: '$heading3xs' },
        [`& ${Text}`]: { typography: '$bodyS' },
      },
    },
    variant: {
      info: {
        [`&, & ${Heading}`]: { color: '$textOnBackgroundVarThree' },
        backgroundColor: '$feedbackBackgroundInfo',
        borderColor: '$feedbackInfo',
        $$iconColor: '$colors$feedbackInfo',
      },
      success: {
        [`&, & ${Heading}`]: { color: '$textOnBackgroundVarFive' },
        backgroundColor: '$feedbackBackgroundSuccess',
        borderColor: '$feedbackSuccess',
        $$iconColor: '$colors$feedbackSuccess',
      },
      warning: {
        [`&, & ${Heading}`]: { color: '$textOnBackgroundVarSix' },
        backgroundColor: '$feedbackBackgroundWarning',
        borderColor: '$feedbackWarning',
        $$iconColor: '$colors$feedbackWarning',
      },
      error: {
        [`&, & ${Heading}`]: { color: '$textPrimary' },
        backgroundColor: '$feedbackBackgroundError',
        borderColor: '$feedbackError',
        $$iconColor: '$colors$feedbackError',
      },
      chat: {
        [`&, & ${Heading}`]: { color: '$textOnBackgroundVarThree' },
        backgroundColor: '$feedbackBackgroundInfo',
        borderColor: '$feedbackInfo',
        $$iconColor: '$colors$feedbackInfo',
      },
    },
  },
  defaultVariants: {
    emphasis: 'low',
    variant: 'info',
  },
});

const NotificationCloseButton = styled('div', {
  position: 'absolute',
  top: '0',
  right: '0',
  paddingTop: '$1',
  paddingRight: '$1',
});

const iconMapper: Record<VariantTypes, React.ReactNode> = {
  info: <InfoIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  chat: <ChatIcon />,
};

const ownClassName = 'notification-box-sparky';

function filterEmphasisObject(emphasis: EmphasisSparky) {
  const forcedEmphasisObject: EmphasisSparky = { initial: 'low' };
  for (const [mq, value] of Object.entries(emphasis)) {
    if (value === 'high' && (mq === 'lg' || mq === 'xl')) {
      forcedEmphasisObject[mq] = value;
      break;
    }
  }

  return forcedEmphasisObject;
}

type CanCloseProps = {
  closeable?: boolean;
  onClose?: (event?: SyntheticEvent) => void;
};

type InnerProps = {
  variant?: VariantTypes;
  title?: string;
  /**
   * The correct heading level of the notification title inside the document.
   */
  headingLevel?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  text: React.ReactNode;
  /** `emphasis` is used to define the importance of the notification. Note: `emphasis` will always be forced into `low` for viewports `sm` and below */
  emphasis?: NotificationVariants['emphasis'];
  icon?: React.ReactNode;
};

export type NotificationProps = Omit<NotificationVariants, 'variant'> & {
  isAlert: boolean;
} & InnerProps &
  CanCloseProps;

export const InnerNotificationBox: React.FC<InnerProps> = ({
  title,
  headingLevel = 'h4',
  text,
  variant = 'info',
  emphasis = 'low',
  icon,
}) => {
  return (
    <Stack direction="row" gap={emphasis === 'high' ? '3' : '2'}>
      <LeftIconWrapper grow={false}>{icon ? icon : iconMapper[variant]}</LeftIconWrapper>
      <Stack>
        {title && <Heading as={headingLevel}>{title}</Heading>}
        <Box paddingTop="1">
          <Text as={typeof text === 'string' ? 'span' : 'div'}>{text}</Text>
        </Box>
      </Stack>
    </Stack>
  );
};

export const NotificationBox = React.forwardRef<HTMLDivElement, React.PropsWithChildren<NotificationProps>>(
  (
    { isAlert, variant = 'info', emphasis = 'low', title, headingLevel, text, isElevated, closeable, onClose, icon },
    ref,
  ) => {
    const closeText = useI18nTranslation('close');
    /**
     * Force a low emphasis on a <1024 window (sm, md):
     * -----------
     * Because of a bug in Stitches we need to add a bunch of extra comparisons to force the input we need to circumvent the bug.
     * We cannot have two of the same responsive variants in one declaration:
     * <Component emphasis={{ initial: 'low', md; 'high', lg: 'low' }} /> -> has 'low' 2 times and will break
     * https://github.com/modulz/stitches/issues/725
     */
    const forcedEmphasis =
      typeof emphasis === 'string'
        ? emphasis === 'high'
          ? { initial: 'low' as const, lg: emphasis }
          : ('low' as const)
        : filterEmphasisObject(emphasis);

    const variantProps = extractVariantProps({ emphasis: forcedEmphasis, isElevated });

    const [hasHandledClose, setHasHandledClose] = useState(false);

    const onCloseHandler = useCallback(
      (event: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        onClose?.(event);
        setHasHandledClose(true);
      },
      [onClose, setHasHandledClose],
    );

    if (hasHandledClose) {
      return;
    }

    return (
      <NotificationBoxStyled
        ref={ref}
        variant={variant}
        {...variantProps}
        className={ownClassName}
        role={isAlert ? 'alert' : undefined}>
        <InnerNotificationBox
          variant={variant}
          title={title}
          headingLevel={headingLevel}
          text={text}
          emphasis={forcedEmphasis}
          icon={icon}
        />
        {closeable && (
          <NotificationCloseButton>
            <IconButton onClick={onCloseHandler} label={closeText} size="regular">
              <CloseIcon />
            </IconButton>
          </NotificationCloseButton>
        )}
      </NotificationBoxStyled>
    );
  },
);

NotificationBox.toString = () => `.${ownClassName}`;

NotificationBoxStyled.displayName = 'styled(NotificationBox)';
NotificationBox.displayName = 'NotificationBox';

type NotificationVariants = TransformStitchesToSparky<typeof NotificationBoxStyled>;
type EmphasisSparky = Extract<NotificationVariants['emphasis'], object>;
export type VariantTypes = Exclude<NotificationVariants['variant'], object | undefined>;
