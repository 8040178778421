export const enecoButtonColors = {
  // Primary onLight Text
  buttonPrimaryOnLightDefaultText: '{{ textColors.textInverted }}',

  // Primary onLight Background
  buttonPrimaryOnLightDefaultBackground: '#007250',
  buttonPrimaryOnLightHoverBackground: `#00593F`,
  buttonPrimaryOnLightFocusBackground: `#00593F`,
  buttonPrimaryOnLightPressedBackground: `#007250`,

  // Primary onDark Text
  buttonPrimaryOnColorDefaultText: '{{ textColors.textPrimary }}',
  buttonPrimaryOnColorHoverText: '{{ textColors.textBrand }}',
  buttonPrimaryOnColorFocusText: '{{ textColors.textBrand }}',
  buttonPrimaryOnColorPressedText: '{{ textColors.textBrand }}',

  // Primary onDark Background
  buttonPrimaryOnColorDefaultBackground: '{{ neutralColors.neutralWhite }}',
  buttonPrimaryOnColorHoverBackground: '{{ neutralColors.neutralWhite }}',
  buttonPrimaryOnColorFocusBackground: '{{ neutralColors.neutralWhite }}',
  buttonPrimaryOnColorPressedBackground: '{{ neutralColors.neutralWhite }}',

  // Primary onDark Icon
  buttonPrimaryOnColorDefaultIcon: '{{ iconColors.iconPrimary }}',
  buttonPrimaryOnColorHoverIcon: '{{ iconColors.iconBrand }}',
  buttonPrimaryOnColorFocusIcon: '{{ iconColors.iconBrand }}',
  buttonPrimaryOnColorPressedIcon: '{{ iconColors.iconBrand }}',

  // Secondary
  buttonSecondaryDefaultText: '{{ textColors.textPrimary }}',
  buttonSecondaryDefaultBackground: '{{ backgroundColors.backgroundPrimary }}',
  buttonSecondaryDefaultBorderColor: '{{ borderColors.borderDividerMediumEmphasis }}',
  buttonSecondaryHoverText: '{{ textColors.textPrimary }}',
  buttonSecondaryHoverBackground: '{{ backgroundColors.backgroundSecondary }}',
  buttonSecondaryHoverBorderColor: '{{ borderColors.borderDividerMediumEmphasis }}',
  buttonSecondaryFocusBackground: '{{ backgroundColors.backgroundPrimary }}',
  buttonSecondaryFocusBorderColor: '{{ borderColors.borderFocus }}',
  buttonSecondaryPressedBackground: '{{ backgroundColors.backgroundTertiary }}',

  // Temporary rebrand definition. Should be removed when the button rebrand a/b test is finished.
  buttonPrimaryRebrandDefaultText: '{{ textColors.textInverted }}',
  buttonPrimaryRebrandDefaultBackground: '#007250',
  buttonPrimaryRebrandHoverBackground: '#00593F',
  buttonPrimaryRebrandPressedBackground: '#007250',

  // Loading
  buttonLoadingDefaultText: '{{ neutralColors.neutralWhite }}',
  buttonLoadingDefaultBackground: '{{ secondaryColors.blue700 }}',
};

export const enecoButtonBorderWidths = {
  buttonSecondaryDefaultBorderWidth: '{{ borderWidths.m }}',
};

export const enecoButtonBorderRadii = {
  buttonBorderRadiusDefault: '{{ radii.s }}',
  // Temporary rebrand definition. Should be removed when the button rebrand a/b test is finished.
  buttonBorderRadiusRebrand: '{{ radii.m }}',
};

export const enecoButtonChevronDisplay = {
  buttonChevronDisplay: 'none',
};
